import React from 'react';
import { Actions, Block, Description, Half, Hero, Layout, Title } from '@dragonchain-dev/dragon-kit';
import {
  Knowledgeperk,
  Search,
  Price,
  Thumbprint,
  Performance,
  Circles,
} from '@dragonchain-dev/dragon-kit-icons';
import { HowDragonchainWorks, LayoutNoNav, FormForbes, SEO } from '../components/_index';

const Forbes = () => {
  const { IMAGES_URI } = process.env;

  return (
    <LayoutNoNav>
      <SEO
        noFollow
        title="Read about us on Forbes? | Dragonchain - Blockchain as a Service"
        description="Blockchain as a Service, powered by Dragonchain. Be a part of the most secure, flexible, scalable and enterprise ready blockchain platform."
        image={`${IMAGES_URI}/banners/forbes.png`}
        cardType="summary_large_image"
      />
      <Hero
        type="code"
        backgroundImage={`${IMAGES_URI}/texture/clouds-blue@2x.jpg`}
        illustration={`${IMAGES_URI}/illustrations/forbes-hero-b@2x.png`}
        illustrationClass="expanded"
      >
        <Title color="white" size="large">
          A blockchain built for your enterprise, starting today
        </Title>
        <Description color="blue-light">
          <p>
            Leverage the skills your developers already have to put your data and business logic on your very
            own private/public hybrid blockchain.
          </p>
          <Actions
            actions={[
              {
                name: 'Request Information',
                color: 'blue',
                hover: 'blue-dark',
                to: '/forbes#contact',
              },
            ]}
          />
        </Description>
      </Hero>
      <Layout dark background="blue-dark">
        <Block center divider>
          <div className="icon">
            <a href="https://github.com/disney/dragonchain" target="_blank" style={{ cursor: 'default' }}>
              <Circles className="icon medium" color="blue" />
            </a>
          </div>
          <Title size="large">Built for Enterprise</Title>
          <p>
            Dragonchain was architected as an enterprise first blockchain platform at The Walt Disney Company.
          </p>
        </Block>
        <Block type="halves" skinny stack>
          <Half center stack>
            <Performance className="icon large" color="blue" />
            <Title>High Performance</Title>
            <p>
              Writing to the ledger is immediate compared to others which take minutes or hours and may be
              slower under load performance.
            </p>
          </Half>
          <Half center stack>
            <Thumbprint className="icon large" color="blue" />
            <Title>Secure</Title>
            <p>Data is never stored on-chain. We leverage the hash power of other chains.</p>
          </Half>
        </Block>
        <Block type="halves" skinny stack>
          <Half center stack>
            <Search className="icon large" color="blue" />
            <Title>Built-in Search</Title>
            <p>Dragonchain is natively searchable and indices can be added as needed.</p>
          </Half>
          <Half center stack>
            <Price className="icon large" color="blue" />
            <Title>Predictable Cost</Title>
            <p>
              Dragonchain allows you to leverage existing enterprise assets. Runs on cloud services and has
              low transaction costs.
            </p>
          </Half>
        </Block>
      </Layout>
      <HowDragonchainWorks />
      <div id="contact" />
      <Layout dark background="blue-dark">
        <Block center stack>
          <div className="icon">
            <a href="https://knowledgeperk.com" target="_blank" style={{ cursor: 'default' }}>
              <Knowledgeperk color="white" />
            </a>
          </div>
          <Title tag="p" size="small" color="blue-light" weight="300">
            {`"I had no idea the technology piece could allow us to go from
            absolutely nothing, to something that is being used in our store
            right now -- today; in 4 days."`}
          </Title>
          <Description>Ryan Sanderson, Knowledge Perk CEO</Description>
        </Block>
        <Block type="halves">
          <Half illustration={{ src: `${IMAGES_URI}/illustrations/forbes-contact@2x.png` }} />
          <Half>
            <Title>Learn more about the benefits of blockchain and Dragonchain</Title>
            <p>Leave us your information and our consulting team will get back to you in 2 business days.</p>

            <FormForbes />
          </Half>
        </Block>
      </Layout>
    </LayoutNoNav>
  );
};

export default Forbes;
